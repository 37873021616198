@import './assets/css/ValidationCss/Validation.css'; /* Using a string */
@import './assets/css/commonCss/common.css';

* {
   font-family: 'Poppins', sans-serif;
   box-sizing: border-box;
   /* height: 100vh; */
}
body {
   overflow: hidden;
   overflow-y: scroll;
   background-color: #eff3f8 !important;
}

a {
   /* color: #5a6ded !important; */
   text-decoration: none !important;
}

.all-component-wrapper {
   min-height: 100vh;
   position: relative;
}

#tabelContainer {
   width: 100vw;
   padding-top: 5rem;
   padding-right: 40px;
   padding-left: 115px;
   padding-bottom: 6rem;
   transition: 0.5s;
}

#tabelContainer-static {
   width: 100vw;
   padding-top: 5rem;
   padding-right: 40px;
   padding-left: 290px;
   padding-bottom: 6rem;
   transition: 0.5s;
}
#mobile-tabelContainer-open {
   width: 100vw;
   padding-top: 40px;
   padding-right: 9px;
   padding-left: 9px;
   padding-bottom: 6rem;
   transition: 0.5s;
}

.tabel-contant {
   display: flex;
   justify-content: center;
}

.tabelContent {
   width: 100vw;
   border: 1px solid #dfe7ef;
   border-radius: 12px;
   background-color: white;
   /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.tabelSearch {
   margin: 20px 42px 20px 42px;
}

.modal_style {
   width: 70vw;
}

.btn-style {
   display: flex;
   margin: 0px !important;
   align-items: center;
}

@media screen and (max-width: 624px) {
   .tabelContent {
      width: 95vw;
      margin-bottom: 20px;
   }
   .modal_style {
      width: 95vw;
   }

   .css-ktfy13-MuiDataGrid-root.MuiDataGrid-root {
      height: auto;
      border: none !important;
      margin: 0.5rem 0.5rem 1.5rem 0.5rem !important;
   }

   .btn-style {
      display: flex;
      justify-content: center;
      margin: 0 !important;
      align-items: center;
   }
}

.card {
   border: none !important;
}

.card .card-header {
   background-color: #fff;
   border-bottom: 1px solid #ecf3fa;
   border-top-left-radius: 15px;
   border-top-right-radius: 15px;
   padding: 40px;
   position: relative;
   letter-spacing: 1px;
}

.card .card-header .h5,
.card .card-header h5 {
   font-size: 22px;
   font-weight: 700;
}

label {
   color: #000;
   font-size: 15px;
   letter-spacing: 0.4px;
}

.p-inputtext-sm::placeholder {
   font-family: 'Poppins', sans-serif;
   letter-spacing: 0.4px;
}

.p-inputtext.p-inputtext-sm {
   font-size: 0.875rem;
   padding: 0.65625rem 0.65625rem;
   color: black;
   letter-spacing: 1px;
}

.p-dialog-content::-webkit-scrollbar {
   display: none; /* for Chrome, Safari, and Opera */
}

/** prime react toast css */

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
   margin: 0 !important;
}

.sidenav {
   height: 100%;
   width: 0;
   position: fixed;
   z-index: 999;
   top: 0;
   left: 0;
   background-color: #ffffff;
   overflow-x: hidden;
   transition: 0.3s;
   box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
}

/* Hoverable sidebar */

#mySidenav {
   width: 85px;
   white-space: nowrap;
}

/* width */
#mySidenav::-webkit-scrollbar {
   width: 5px;
   border-radius: 12px;
   scroll-behavior: smooth;
}

/* Track */
#mySidenav::-webkit-scrollbar-track {
   background: #f1f1f1;
   border-radius: 12px;
}

/* Handle */
#mySidenav::-webkit-scrollbar-thumb {
   background: #e2e2e2;
   border-radius: 12px;
}

/* Handle on hover */
#mySidenav::-webkit-scrollbar-thumb:hover {
   background: #e5e5e5;
}

#mySidenav:hover {
   width: 250px !important;
}
#mySidenav:hover .show-title {
   display: block !important;
}

.show-title {
   display: none !important;
}

/* Static sidebar */

#mySidenav-static {
   width: 250px;
   white-space: nowrap;
}

#mySidenav-static .show-title {
   display: block !important;
}

/* width */
#mySidenav-static::-webkit-scrollbar {
   width: 5px;
   border-radius: 12px;
   scroll-behavior: smooth;
}

/* Track */
#mySidenav-static::-webkit-scrollbar-track {
   background: #f1f1f1;
   border-radius: 12px;
}

/* Handle */
#mySidenav-static::-webkit-scrollbar-thumb {
   background: #e2e2e2;
   border-radius: 12px;
}

/* Handle on hover */
#mySidenav-static::-webkit-scrollbar-thumb:hover {
   background: #e5e5e5;
}

/* Mobile sidebar open */

#mobile-View-sidebar-open {
   width: 275px;
   white-space: nowrap;
}

/* width */
#mobile-View-sidebar-open::-webkit-scrollbar {
   width: 5px;
   border-radius: 12px;
   scroll-behavior: smooth;
}

/* Track */
#mobile-View-sidebar-open::-webkit-scrollbar-track {
   background: #f1f1f1;
   border-radius: 12px;
}

/* Handle */
#mobile-View-sidebar-open::-webkit-scrollbar-thumb {
   background: #e2e2e2;
   border-radius: 12px;
}

/* Handle on hover */
#mobile-View-sidebar-open::-webkit-scrollbar-thumb:hover {
   background: #e5e5e5;
}
/* Mobile sidebar close */

#mobile-View-sidebar-close {
   width: 0px;
   white-space: nowrap;
}

/* width */
#mobile-View-sidebar-close::-webkit-scrollbar {
   width: 5px;
   border-radius: 12px;
   scroll-behavior: smooth;
}

/* Track */
#mobile-View-sidebar-close::-webkit-scrollbar-track {
   background: #f1f1f1;
   border-radius: 12px;
}

/* Handle */
#mobile-View-sidebar-close::-webkit-scrollbar-thumb {
   background: #e2e2e2;
   border-radius: 12px;
}

/* Handle on hover */
#mobile-View-sidebar-close::-webkit-scrollbar-thumb:hover {
   background: #e5e5e5;
}

.sidenav ul > div > li {
   padding: 5px 5px 3px 5px;
   text-decoration: none;
   color: #818181;
   display: block;
   transition: 0.3s;
   margin: 0;
}

.sidebar-header {
   padding: 20px 12px 20px 208px;
   display: flex;
   /* justify-content: end; */
   box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
}

.sidebar-toggle-btn {
   padding: 4px 7px 1px 7px;
   margin: 0;
   cursor: pointer;
}

.sidebar-toggle-btn:hover {
   color: rgb(99, 102, 241);
   background-color: rgb(210 211 255);
   border-radius: 20px;
   transform: scale(1);
   transition: all 0.3s ease;
}

.fill-sidebar-btn {
   padding: 4px 7px 1px 7px;
   color: rgb(99, 102, 241);
   background-color: rgb(210 211 255);
   border-radius: 20px;
   cursor: pointer;
}

.sidenav .closebtn {
   position: absolute;
   top: 0;
   right: 25px;
   font-size: 36px;
   margin-left: 50px;
}

#main {
   transition: margin-left 0.5s;
   padding: 16px;
}

@media screen and (max-height: 450px) {
   .sidenav {
      padding-top: 15px;
   }
   .sidenav ul > div > li {
      font-size: 18px;
      margin: 0;
   }
}

/* side bar css */

.sidenav ul {
   padding: 0;
   margin: 10px 10px;
}

.sidenav ul > div > li > .static-href {
   display: flex;
   /* margin: 5px 5px 5px 5px; */
   border-radius: 10px;
   padding: 10px 20px;
   color: black !important;
   cursor: pointer;
   text-decoration: none;
}
.sidenav ul > div > li > .static-href:hover {
   display: flex;
   /* margin: 5px 5px 5px 5px; */
   border-radius: 10px;
   padding: 10px 20px;
   color: black !important;
   cursor: pointer;
   text-decoration: none;
   background-color: #f6f9fc !important;
   border-radius: 12px;
}
.sidenav ul > div > li > .static-href > i {
   font-size: 1rem;
   display: flex;
   justify-content: center;
   align-items: center;
   padding-right: 20px;
}
.sidenav ul > div > li {
   border-radius: 12px;
}

/* .submenu-open {
  display: block;
  opacity: 1;
  transition: opacity 1s ease-in 0s;
} */
.submenu-open {
   transition: max-height 5s ease-in;
   /* max-height: 400px; */
   max-height: 500rem;
   overflow: hidden;
}
.submenu-close {
   /* display: none; */
   /* transition: 2s;
  opacity: 0; */
   max-height: 0rem;
   overflow: hidden;
   margin: 0px !important;
}

.sidenav ul > li > ul {
   /* background-color: #f8f8f8; */
}

.moon-light-btn {
   display: flex;
   justify-content: center;
   align-items: center;
   padding-right: 15px;
   cursor: pointer;
}

.sidenav ul > div > li > ul > li > a:hover {
   color: #7366ff;
   transition: 0.3s;
   cursor: pointer;
}
.sidenav li > ul > li > a > span {
   padding-right: 16px;
   padding-left: 20px;
}
.sidenav li > ul > li > a {
   padding: 0;
   color: gray;
}

.sidenav ul > div > li > a:hover {
   color: rgb(27, 27, 27);
   transition: 0.4s;
}

.layout-topbar-right {
   background-color: #ffffff;
   position: fixed;
   width: 100vw;
   top: 0;
   z-index: 9;
   box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
   /* padding-left: 75px; */
}

.layout-topbar-right > ul {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-right: 35px;
   padding-top: 20px;
   padding-bottom: 20px;
   margin: 0;
}

.layout-topbar-right > ul > li {
   /* padding-right: 75px; */
   list-style-type: none;
}

/* open dropdowna and close dropdown css */

.inDropdown-close {
   display: block;
   position: absolute;
   top: -150px;
   background: white;
   z-index: 1;
   right: 30px;
   padding: 0;
   box-shadow: 0 2px 8px 0 rgba(25, 26, 28, 0.12);
   width: 12rem;
   transition: 0.3s;
}

.inDropdown-close > li {
   list-style-type: none;
   color: rgba(41, 50, 65, 0.8);
   padding: 5px 15px;
   margin-top: 3px;
   font-size: 13px;
}

.inDropdown-close > li:hover {
   background-color: rgba(70, 77, 242, 0.2);
   transition: 0.3s;
}

.inDropdown {
   display: block;
   position: absolute;
   top: 72px;
   background: white;
   z-index: 1;
   right: 30px;
   padding: 0;
   box-shadow: 0 2px 8px 0 rgba(25, 26, 28, 0.12);
   width: 12rem;
   transition: 0.3s;
}
.inDropdown > li {
   list-style-type: none;
   color: rgba(41, 50, 65, 0.8);
   padding: 5px 15px;
   margin-top: 3px;
   font-size: 13px;
   cursor: pointer;
}

.inDropdown > li:hover {
   background-color: rgba(70, 77, 242, 0.2);
   transition: 0.3s;
}

.layout-mask {
   display: none;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   animation-duration: 0.2s;
   animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
   animation-fill-mode: forwards;
}

.modal-in {
   animation-name: modal-in;
}

@keyframes modal-in {
   0% {
      background-color: transparent;
   }
   100% {
      background-color: rgb(0 0 0 / 60%);
   }
}

.footer-static {
   background: #fff;
   position: absolute;
   /* top: 100vh; */
   bottom: 0;
   color: black;
   display: flex;
   justify-content: center;
   /* margin-left: 15.5rem; */
   width: 100vw;
   padding: 15px;
   padding-left: 16rem;
   box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
   font-size: 13px;
   line-height: 1.7;
   letter-spacing: 0.7px;
   z-index: 1;
   transition: 0.5s;
}
.footer-normal {
   background: #fff;
   position: absolute;
   /* top: 100vh; */
   bottom: 0;
   color: black;
   display: flex;
   justify-content: center;
   /* margin-left: 4.5rem; */
   width: 100vw;
   padding: 15px;
   padding-left: 4.5rem;
   box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
   font-size: 13px;
   line-height: 1.7;
   letter-spacing: 0.7px;
   z-index: 1;
   transition: 0.5s;
}

.navbar-search {
   display: flex;
   margin-right: 20px;
   border-radius: 7px;
}

.navbar-search > button {
   padding: 12px;
   color: gray;
   position: absolute;
   z-index: 1;
}

.search-input-wrapper > .p-inputtext {
   background: #f7faff !important;
   width: 100% !important;
   position: relative !important;
   padding: 9px !important;
   padding-left: 37px !important;
   border: none !important;
   color: #3e4754 !important;
}

.active-min {
   background-color: #eff3f8;
   transition: 0.4s;
}
.active-sub {
   color: #4338ca !important;
   /* transition: 0.3s; */
}

/* risponsive mobile ,tab, laptops, ipad  screen */
@media (max-width: 991.98px) {
   /* navbar */
   .layout-topbar-right > ul > li {
      /* padding-right: 38px; */
      list-style-type: none;
   }
   /* navbar logo */
   .layout-mask {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation-duration: 0.2s;
      animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
      animation-fill-mode: forwards;
   }

   /* navbar dropdown */
   .inDropdown {
      display: block;
      position: absolute;
      top: 72px;
      background: white;
      z-index: 1;
      right: 35px;
      padding: 0;
      box-shadow: 0 2px 8px 0 rgba(25, 26, 28, 0.12);
      width: 12rem;
      transition: 0.3s;
   }

   .inDropdown-close {
      display: block;
      position: absolute;
      top: -150px;
      background: white;
      z-index: 1;
      right: 35px;
      padding: 0;
      box-shadow: 0 2px 8px 0 rgba(25, 26, 28, 0.12);
      width: 12rem;
      transition: 0.3s;
   }

   .modal-in {
      animation-name: modal-in;
   }

   #mySidenav {
      width: 0px;
   }
   /* 
  #tabelContainer {
    padding-left: 40px !important;
  } */

   @keyframes modal-in {
      0% {
         background-color: transparent;
      }
      100% {
         background-color: rgb(0 0 0 / 60%);
      }
   }

   .sidebar-header {
      padding: 25px 12px 20px 90px;
      display: flex;
      box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
      justify-content: flex-end;
   }

   .footer-normal {
      background: #fff;
      position: sticky;
      top: 100vh;
      color: black;
      display: flex;
      justify-content: center;
      padding: 15px;
      box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
      font-size: 13px;
      line-height: 1.7;
      letter-spacing: 0.7px;
      z-index: 1;
      transition: 0.5s;
      margin: 0;
   }

   .navbar-search > button {
      padding: 12px;
      color: black;
      position: static;
   }
   .navbar-search > button:hover {
      padding: 12px;
      color: black;
      background-color: #e8edf0;
      position: static;
      border-radius: 12px;
   }
}

.loading-mask {
   display: none;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 999;
   background-color: rgb(0 0 0 / 60%);
   animation-duration: 0.2s;
   animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
   animation-fill-mode: forwards;
}
@keyframes loading-modal-in {
   0% {
      background-color: transparent;
   }
   100% {
      background-color: rgb(0 0 0 / 60%);
   }
}

.common-card {
   background: #ffffff;
   border: 1px solid #dfe7ef;
   padding: 2rem;
   box-shadow: 0px 4px 30px rgba(221, 224, 255, 0.54);
   border-radius: 12px;
}

.custom-file-input {
   /* left: 0;  */
   opacity: 0;
   position: absolute;
   /* right: 0; */
   width: 227px;
   height: 41px;
   z-index: 999;
}
.custom-file-input-product {
   opacity: 0;
   position: relative;
   top: -8px;
   height: 109.5%;
   z-index: 999;
}

/* all heading css */

.p-dataview-header {
   background: #f8fafc;
   color: #334155;
   border: 1px solid #e2e8f0;
   border-width: 1px 0 1px 0;
   padding: 1rem 1rem;
   font-weight: 700;
}

.contmainainer {
   display: flex;
   align-items: center;
   margin: 20px 0;
}

.input-field {
   padding: 10px;
   border: 1px solid #000; /* Adjust border styles as needed */
}

.or-separator {
   margin: 0 10px;
}

/* password css */

.p-password > .p-inputtext {
   position: relative !important;
   display: inline-flex !important;
   box-sizing: border-box !important;
   width: 100% !important;
   font-family: 'Poppins', sans-serif !important;
   font-size: 1rem !important;
   font-weight: normal !important;
   padding: 0;
}
.p-password .p-password-input {
   width: 100%;
   padding-left: 2.5rem;
}

.user-detail-tabel table {
   border: none;
   font-size: 16px;
   height: 270px;
   width: 80%;
}

.card-container {
   width: 160px !important;
}

/* Default styles for the web view */
#donationReceipt {
   display: none; /* Hide the receipt on the web page by default */
}
#printableArea {
   display: none; /* Hide the receipt on the web page by default */
}

@media print {
   body {
      visibility: hidden;
   }
   #donationReceipt,
   #donationReceipt {
      visibility: visible;
      display: block;
      width: 100% !important;
   }
   #hide-content,
   #hide-content {
      display: none;
   }
   .page-header {
      display: none;
   }
   .footer {
      display: none;
   }
   #donationReceipt {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
   }
   .MuiDataGrid-selectedRowCount {
      display: none !important;
   }
}
