.card-div {
   padding: 2rem;
   /* border: 1px solid #dfe7ef !important; */
   background: #ffffff;
   box-shadow: 0px 4px 30px rgba(221, 224, 255, 0.54);
   border-radius: 12px !important;
}

.border {
   border: 1px solid #dfe7ef !important;
}

.layout-breadcrumb ol {
   display: flex;
   align-items: center;
   margin: 0;
   padding: 0;
   list-style: none;
   gap: 1rem;
   flex-wrap: wrap;
   color: #64748b;
}

.topbar-breadcrumb {
   padding: 15px;
   padding-bottom: 20px;
   padding-top: 20px;
}

.button-gap {
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
   gap: 15px;
   padding-top: 15px;
}

/* validation error color */

.error-validation {
   color: #ff3d32;
   font-size: 13px;
}

/* Dark Mode */
.dark-mode {
   background: #071426 !important;
   border-right: 1px solid #0b213f;
}
.dark-nav {
   background-color: #040d19;
   border-bottom: 1px solid #0b213f;
}

.dark-side-title {
   color: #a5b4fc !important;
}

.dark-side-menu {
   color: rgba(255, 255, 255, 0.788) !important;
}

.sidenav ul > li > .dark-static-href {
   display: flex !important;
   /* margin: 5px 5px 5px 5px; */
   border-radius: 10px !important;
   padding: 10px 20px !important;
   color: black !important;
   cursor: pointer !important;
   text-decoration: none !important;
}

.sidenav > ul > li > .dark-static-href:hover {
   display: flex !important;
   /* margin: 5px 5px 5px 5px; */
   border-radius: 10px !important;
   padding: 10px 20px !important;
   color: black !important;
   cursor: pointer !important;
   text-decoration: none !important;
   background-color: rgb(255 255 255 / 3%) !important;
   border-radius: 12px !important;
}

.sidenav ul > li > .dark-static-href > i {
   font-size: 1rem;
   display: flex;
   justify-content: center;
   align-items: center;
   padding-right: 20px;
}

.active-dark {
   background-color: rgb(255 255 255 / 3%);
   transition: 0.4s;
}

.dark-active-sub {
   color: #a5b4fc !important;
   /* transition: 0.3s; */
}

.dark-footer {
   background-color: #040d19 !important;
   border-top: 1px solid #0b213f;
   color: rgba(255, 255, 255, 0.788) !important;
}

.dark-link {
   color: #a5b4fc !important;
}

.dark-container {
   background-color: #040d19 !important;
}

.dark-tabel {
   background-color: #071426 !important;
   border: 1px solid #0b213f !important;
}

.dark-input {
   height: 45px !important;
   background: #040d19 !important;
   border: 1px solid #0b213f !important;
}

/* sweet alert btn style */
.swal2-cancel {
   padding: 20px;
}

.swal2-deny {
   padding: 20px;
}

.swal2-confirm {
   padding: 20px;
}

.aj-uploader {
   border: 1px dashed #d3d3d3;
   border-radius: 5px;
   height: 100px;
}

/* global setting tabel css */

.customeStyleTabel > div {
   width: 8rem;
}

.custom-device {
   width: 100vw; /* Default to 100% width */
}

@media (min-width: 768px) {
   /* For screens 768px and wider (e.g., medium screens and above) */
   .custom-device {
      width: 50vw; /* Adjust to 50% width */
   }
}

.attribute-selection .p-fieldset .p-fieldset-legend {
   position: relative;
   width: 140px;
   font-weight: 300;
   font-size: 17px;
   top: -25px;
   left: 20px;
   margin: 0;
}
.attribute-selection .p-fieldset .p-fieldset-legend a {
   padding: 12px !important;
}

.attribute-selection .p-toggleable-content .p-fieldset-content {
   padding-top: 0;
}

@media (min-width: 768px) {
   .pos-checkout-button {
      flex-wrap: wrap;
   }
}
@media (min-width: 1307px) {
   .pos-checkout-button {
      flex-wrap: nowrap;
   }
}
@media (max-width: 414px) {
   .pos-checkout-button {
      flex-wrap: wrap;
   }
}
